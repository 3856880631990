import React from 'react';
import { graphql, Link } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';

import '../styles/main.scss';
import '../styles/layout/blog.scss';

import BlogPostCard from '../components/BlogPostCard';
import SEO from '../components/seo';
import Layout from '../components/Layout';

import blogTagsHelper from '../Helpers/BlogTagsHelper';

function getTagsListFromBlogPosts(blogPosts, tagSlug, language) {
  const tagsOut = [];
  let overallCount = 0;

  blogPosts.forEach((blogPost) => {
    blogPost.frontmatter.tags.forEach((tagLine) => {
      const slug = tagLine;
      const title = blogTagsHelper.getTitleFromSlug(tagLine);

      const existing = tagsOut.find((tag) => tag.slug === slug);
      if (existing) {
        existing.postCount += 1;
      } else {
        tagsOut.push({
          title,
          slug,
          isActive: slug === tagSlug,
          postCount: 1,
          linkTo:
            language === 'en'
              ? `/en/articles/tag/${slug}/`
              : `/artiklid/tag/${slug}/`,
        });
      }
    });
    overallCount++;
  });

  tagsOut.unshift({
    title: 'Kõik',
    slug: 'all',
    isActive: tagSlug === 'all',
    postCount: overallCount,
    linkTo: language === 'en' ? `/en/articles/` : `/artiklid/`,
  });

  return tagsOut;
}

function BlogPage({ data, pageContext }) {
  const { language } = useI18next();
  const { currentPage = 1, totalPages = 1, tagSlug = 'all' } = pageContext;

  const siteUrl = data.site.siteMetadata.siteUrl;
  const allPostsFull = data?.allPostsFull?.nodes || [];

  const paginatedPosts =
    tagSlug !== 'all' && data?.taggedPosts?.nodes?.length
      ? data.taggedPosts.nodes
      : data?.posts?.nodes || [];

  const blogTagsList = getTagsListFromBlogPosts(allPostsFull, tagSlug, language);
  const { heading, description, metaDescription } = blogTagsHelper.getMetaFromSlug(tagSlug, language);
  const canonical = blogTagsHelper.getCanonical(tagSlug, language, currentPage);

  const postListItems = paginatedPosts.map((post, index) => ({
    "@type": "ListItem",
    "position": index + 1,
    "url": `${siteUrl}${language === 'en' ? '/en/articles' : '/artiklid'}/${post.frontmatter.slug}/`
  }));

  const collectionPageSchema = {
    "@type": "CollectionPage",
    "name": heading,
    "description": metaDescription,
    "url": `${siteUrl}${canonical}`,
    "mainEntity": {
      "@type": "ItemList",
      "itemListElement": postListItems,
    }
  };
  const webPageSchema = {
    "@type": "WebPage",
    "url": `${siteUrl}${canonical}`,
    "name": heading,
    "description": metaDescription
  };

  const breadcrumbItems = [
    {
      "@type": "ListItem",
      "position": 1,
      "name": language === 'en' ? 'Home' : 'Avaleht',
      "item": `${siteUrl}/`
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": language === 'en' ? 'Articles' : 'Artiklid',
      "item": `${siteUrl}${language === 'en' ? '/en/articles/' : '/artiklid/'}`
    }
  ];

  if (tagSlug !== 'all') {
    breadcrumbItems.push({
      "@type": "ListItem",
      "position": 3,
      "name": blogTagsHelper.getTitleFromSlug(tagSlug),
      "item": `${siteUrl}${canonical}`
    });
  }

  const breadcrumbSchema = {
    "@type": "BreadcrumbList",
    "itemListElement": breadcrumbItems
  };

  const structuredData = [webPageSchema, collectionPageSchema, breadcrumbSchema];



  const paginationBase = () => {
    return tagSlug !== 'all'
      ? language === 'en'
        ? `/en/articles/tag/${tagSlug}`
        : `/artiklid/tag/${tagSlug}`
      : language === 'en'
      ? '/en/articles'
      : '/artiklid';
  };

  return (
    <Layout>
      <main className="outer-grid article-page">
        <SEO
          title={heading}
          description={metaDescription}
          pathname={canonical}
          structuredData={structuredData}
        />

        <section className="hero">
          <h1>{heading}</h1>
        </section>

        <section className="description">
          <div className="page-copy">
            <p>{description}</p>
          </div>
        </section>

        <section className="post-tags">
          {blogTagsList.map((tag) => (
            <Link
              to={tag.linkTo}
              className={`tag${tag.isActive ? ' active' : ''}`}
              key={tag.slug}
            >
              <span className="name">{tag.title}</span>
              <span className="count">{tag.postCount}</span>
            </Link>
          ))}
        </section>

        <div className="blog-grid">
          {paginatedPosts.map((post) => (
            <BlogPostCard key={post.id} post={post} />
          ))}
        </div>

        {totalPages > 1 && (
          <nav className="pagination">
            {Array.from({ length: totalPages }).map((_, i) => {
              const page = i + 1;
              const base = paginationBase();
              const path =
                page === 1
                  ? `${base}/`
                  : `${base}/${language === 'en' ? 'page' : 'lehekulg'}/${page}/`;

              return (
                <Link
                  key={page}
                  to={path}
                  className={page === currentPage ? 'active' : ''}
                >
                  {page}
                </Link>
              );
            })}
          </nav>
        )}
      </main>
    </Layout>
  );
}


export default BlogPage;

export const query = graphql`
  query blogListQuery(
    $language: String!
    $skip: Int!
    $limit: Int!
    $tagSlug: String
  ) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    allPostsFull: allMdx(
      filter: {
        internal: { contentFilePath: { regex: "/blog/" } }
        frontmatter: {
          language: { eq: $language }
          published: { ne: "no" }
        }
      }
    ) {
      nodes {
        frontmatter {
          tags
        }
      }
    }

    taggedPosts: allMdx(
      filter: {
        internal: { contentFilePath: { regex: "/blog/" } }
        frontmatter: {
          language: { eq: $language }
          published: { ne: "no" }
          tags: { in: [$tagSlug] }
        }
      }
      sort: { fields: [frontmatter___orderbynumber], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...PostFields
      }
    }

    posts: allMdx(
      filter: {
        internal: { contentFilePath: { regex: "/blog/" } }
        frontmatter: {
          language: { eq: $language }
          published: { ne: "no" }
        }
      }
      sort: { fields: [frontmatter___orderbynumber], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...PostFields
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }

  fragment PostFields on Mdx {
    id
    frontmatter {
      slug
      title
      tags
      language
      titleForView
      coverimage {
        childImageSharp {
          gatsbyImageData(width: 1024, layout: CONSTRAINED)
        }
      }
      thumbnail {
        childImageSharp {
          gatsbyImageData(width: 1024, layout: CONSTRAINED)
        }
      }
      coverimagealt
      thumbnailalt
      orderbynumber
      published
    }
  }
`;
