const BlogTagsMeta = {
  all: {
    heading: {
      et: 'Digiturundus artiklid',
      en: 'Digital marketing articles',
    },
    description: {
      et: 'Kirjutame UI/UXist, kodulehtede tegemisest, no-code tööriistadest, brändingust, digiturundusest, SEO-st ja väike agentuuri elust.',
      en: 'We write about UI/UX design, building websites with code or no-code tools, branding, marketing, SEO, and small digital agency life. And we do it openly without filters.',
    },
    metaDescription: {
      et: 'Artiklid teemadel UI/UX disainist, brändinguni. Kuidas teha parimaid kodulehti ja turundust veebis. Ausalt ja avatult.',
      en: 'Articles on UI/UX design, building websites, no-code tools, and digital marketing.',
    },
  },
  framer: {
    title: 'Framer',
    heading: {
      et: 'Framer kodulehtede tegemise artiklid',
      en: 'Framer website development articles',
    },
    description: {
      et: 'Kirjutame Framer "no-code" kodulehtede tegemisest ning miks see on 2025 aastal üks parimaid viise. Toome oma tehtud töödest näiteid.',
      en: 'We write about building websites with Framer no-code tool and explain why this is one of the best options in 2025. Also you can see some of our projects here. ',
    },
    metaDescription: {
      et: 'Tahad teada miks Framer on 2025 aastal parim lahendus kodulehtede tegemiseks? Loe ja saa inspiratsiooni.',
      en: 'Discover why Framer is the best tool to build websites on 2025. Tips and workflows for building websites. Get inspired.',
    },
  },
  webflow: {
    title: 'Webflow',
    heading: {
      et: 'Webflow teemalised artiklid',
      en: 'Webflow articles',
    },
    description: {
      et: 'Kirjutame Webflow "no-code" põhjal kodulehtede tegemisest. Mis uudised on Webflow maailmas ja kuidas see teiste no-code lahendustega konkureerib.',
      en: 'We write about building websites with Webflow as a no-code tool. What’s new in the Webflow world and how to make your sites stand out.',
    },
    metaDescription: {
      et: 'Webflow kodulehtedega seotud artiklid. Saa inspiratsiooni ja õpi juurde.',
      en: 'Workflows and tips for building with Webflow. Learn how to create better websites.',
    },
  },
  'graafiline-disain': {
    title: 'Graafiline disain',
    heading: {
      et: 'Graafilise disaini artiklid',
      en: 'Graphic design articles',
    },
    description: {
      et: 'Logo, värvid, tüpograafia ja visuaalne keel. Kirjutame, kuidas graafiline disain toetab brändi ja veebilehe edu.',
      en: 'Logos, colors, typography, and visual systems. We write how graphic design shapes brands and web presence.',
    },
    metaDescription: {
      et: 'Graafiline disain ja brändi visuaalne keel. Loe ja mõtle kaasa.',
      en: 'Graphic design and brand visuals. Read and get inspired.',
    },
  },
  digiturundus: {
    title: 'Digiturundus',
    heading: {
      et: 'Digiturunduse artiklid',
      en: 'Digital marketing articles',
    },
    description: {
      et: 'Jagame mõtteid ja kogemusi, kuidas turundada digitaalses meedias. Veebilehed ja kujundused mis töötavad, sisu, reklaamid ja kõik mis sinna vahele jääb.',
      en: 'Thoughts and insights on digital marketing. From SEO to content to ads — and everything between.',
    },
    metaDescription: {
      et: 'Digiturundus tänases maailmas. Nõuanded ja ideed turundajalt turundajale.',
      en: 'Digital marketing today. Tips and ideas from marketers to marketers.',
    },
  },
  SEO: {
    title: 'SEO',
    heading: {
      et: 'SEO artiklid',
      en: 'SEO articles',
    },
    description: {
      et: 'Kuidas saada oma koduleht Google’is esimeseks? SEO tööriistad, mõtteviis ja strateegia.',
      en: 'How to rank first on Google search? SEO tools, mindset, and long-term strategy.',
    },
    metaDescription: {
      et: 'SEO artiklid kodulehtedele. Mõtle strateegiliselt ja saa Google’is esimeseks.',
      en: 'SEO for websites. Think long-term and move to the top spot in Google.',
    },
  },
  'ui-ux': {
    title: 'UI/UX',
    heading: {
      et: 'UI/UX disaini artiklid',
      en: 'UI/UX design articles',
    },
    description: {
      et: 'Räägime kasutajakogemusest ja liidestest. Kuidas disain mõjutab tulemusi?',
      en: 'Let’s talk about interfaces and experience. How does design drive results?',
    },
    metaDescription: {
      et: 'UI ja UX artiklid, mis aitavad sul paremini disainida.',
      en: 'UI and UX articles to help you design smarter.',
    },
  },
  disain: {
    title: 'Disain',
    heading: {
      et: 'Disaini artiklid',
      en: 'Design articles',
    },
    description: {
      et: 'Disain ei ole ainult ilu – see on ka mõtlemine, protsess ja probleemide lahendamine. Loe ja saad teada kuidas läbi oskusliku disaini ettevõtted kasvavad.',
      en: 'Design is not just about looks – it’s thinking, process, and solving real problems. Read and get to know how companies grow through good design.',
    },
    metaDescription: {
      et: 'Disaini artiklid inimestelt, kes seda ise teevad.',
      en: 'Design articles from people who actually design.',
    },
  },
  wordpress: {
    title: 'WordPress',
    heading: {
      et: 'WordPress kodulehed',
      en: 'WordPress websites',
    },
    description: {
      et: 'WordPress sisuhaldussüsteemi teemalised artiklid. Jagame mõtteid ja kogemusi.',
      en: 'WordPress is most popular CMS. We share thoughts and tips from our own builds.',
    },
    metaDescription: {
      et: 'WordPress artiklid ja mõtted kodulehtede tegemisest.',
      en: 'WordPress insights and thoughts on website building.',
    },
  },
  branding: {
    title: 'Bränding',
    heading: {
      et: 'Brändingu artiklid',
      en: 'Branding articles',
    },
    description: {
      et: 'Brändi loomine ja arendamine. Kuidas disain ja sõnum töötavad koos?',
      en: 'Building and evolving a brand. How do visuals and voice work together?',
    },
    metaDescription: {
      et: 'Brändingu artiklid ettevõtte identiteedi arendamiseks.',
      en: 'Branding insights to grow your company’s identity.',
    },
  },
};



class BlogTagsHelper {
  constructor() {
    this.meta = BlogTagsMeta;
  }

  getTitleFromSlug(slug) {
    const overrides = {
      webflow: 'WebFlow',
      wordpress: 'WordPress',
      'vaikeagentuuri-elu': 'Väikeagentuuri elu',
      seo: 'SEO',
      branding: 'Bränding',
    };
    return overrides[slug] || this.capitalizeFirst(slug);
  }

  getMetaFromSlug(slug, language) {
    const meta = this.meta[slug] || {};
    const fallbackTitle = this.getTitleFromSlug(slug);

    return {
      heading: meta.heading?.[language] || fallbackTitle,
      description:
        meta.description?.[language] ||
        (language === 'en'
          ? 'We write about UI/UX, websites, branding, marketing, SEO, and agency life.'
          : 'Kirjutame UI/UXist, kodulehtedest, brändingust, turundusest, SEO-st ja agentuuri elust.'),
      metaDescription:
        meta.metaDescription?.[language] ||
        (language === 'en'
          ? 'Articles about UI/UX, design, websites and agency life.'
          : 'Artiklid UI/UXi, disaini, kodulehtede ja agentuuri elu kohta.'),
    };
  }

  getCanonical(tagSlug, language, currentPage = 1) {
    const isDefaultTag = tagSlug === 'all';
    const base = isDefaultTag
      ? language === 'en'
        ? '/en/articles'
        : '/artiklid'
      : language === 'en'
      ? `/en/articles/tag/${tagSlug}`
      : `/artiklid/tag/${tagSlug}`;

    if (currentPage === 1) {
      return `${base}/`;
    }

    const pageSegment = language === 'en' ? 'page' : 'lehekulg';
    return `${base}/${pageSegment}/${currentPage}/`;
  }

  capitalizeFirst(s) {
    return typeof s === 'string' ? s.charAt(0).toUpperCase() + s.slice(1) : '';
  }
}

export default new BlogTagsHelper();
