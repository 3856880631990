import React, { useRef, useEffect, useState } from 'react';

import { gsap, Power4 } from 'gsap';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { ListTLink, animateObjects, newContent } from './Layout';
import MagneticButton from './MagneticButton';

import { useI18next } from 'gatsby-plugin-react-i18next';


import blogTagsHelper from '../Helpers/BlogTagsHelper';


export default function BlogPostCard({ post }) {
  // const { title, excerpt, slug, language, tags, timeToRead } = props

  let postPreviewCard = useRef(null);

  const [postPreviewImage, setPostPreviewImage] = useState(null);
  const [postPreviewCardMeta, setPostPreviewCardMeta] = useState(null);
  const [postPreviewButton, setpPostPreviewButton] = useState(null);
  const [gsapTimeLine, setGsapTimeLine] = useState(null);

  useEffect(() => {
    /*eslint-disable */
    //let postPreviewImage = postPreviewCard;
    /* eslint-enable */

    setPostPreviewImage(postPreviewCard.children[1].children[2]);
    setPostPreviewCardMeta(postPreviewCard.children[1].children[0]);
    setpPostPreviewButton(postPreviewCard.children[1].children[1]);

    gsap.set(postPreviewCard.children[1].children[1], { autoAlpha: 0 });
    // console.log(postPreviewButton);
    // console.log(postPreviewCard.current);
    // const postPreviewImage = postPreviewCard.children[0].children[0];
    // const postPreviewImage = document.getElementsByClassName('post-thumbnail');
    // const postPreviewTitle = document.getElementsByClassName('card-meta');
    // const postPreviewButton = document.getElementsByClassName('cta');

    const tl = gsap.timeline({
      repeat: 0,
      paused: true,
    });

    tl.to(postPreviewCard.children[1].children[0], {
      y: -100,
      duration: 0.25,
      ease: Power4.easeIn,
    });

    tl.to(
      postPreviewCard.children[1].children[1],
      {
        autoAlpha: 1,
        duration: 0.25,
        ease: Power4.easeIn,
      },
      '<0.15'
    );

    tl.to(
      postPreviewCard.children[1].children[2],
      {
        scale: 1.2,
        duration: 0.5,
        ease: Power4.easeInOut,
      },
      '<'
    );

    setGsapTimeLine(tl);
  }, []);

  function mouseOverEffect({ pageX: x, pageY: y }) {
    // console.log(postPreviewButton);
    // console.log(postPreviewCardMeta);
    gsapTimeLine.play();
  }

  function mouseOutEffect({ pageX: x, pageY: y }) {
    gsapTimeLine.reverse();
    // gsapTimeLine.play();

    /** 
    const tlout = gsap.timeline({ repeat: 0 });
    tlout.to(postPreviewButton, {
      autoAlpha: 0,
      duration: 0.25,
      ease: Power4.easeIn,
    });
    tlout.to(postPreviewImage, {
      scale: 1,
      duration: 0.25,
      ease: Power4.easeIn,
    });
    tlout.to(
      postPreviewCardMeta,
      { y: 0, duration: 0.25, ease: Power4.easeIn },
      '<'
    );
    */
  }
  
  // By default use title. If titleForView set, use that instead
  let useTitleForView = post.frontmatter.title;
  if( typeof post.frontmatter.titleForView !== "undefined" && post.frontmatter.titleForView !== "" && post.frontmatter.titleForView !== null ){
    useTitleForView = post.frontmatter.titleForView;
  }

  const image = getImage(post.frontmatter.thumbnail);

  const { language } = useI18next();

const isEnglishPost = post.frontmatter.language?.toLowerCase() === 'en';
const basePath = post.frontmatter.language === 'en' ? '/articles' : '/artiklid';
const postUrl = `${basePath}/${post.frontmatter.slug}/`;


  return (

    
    <div
      className="blog-post-card card-top"
      // onMouseMove={handleMouse}
      onMouseOver={mouseOverEffect}
      onFocus={mouseOverEffect}
      onMouseOut={mouseOutEffect}
      onBlur={mouseOutEffect}
      // ref={(el) => (postPreviewCard = el)}
      ref={(el) => (postPreviewCard = el)}
    >
      <div className="post-tags">
        {/* <div className="tag">{post.frontmatter.category}</div> */}
        {post.frontmatter.tags.map((singletag) => (
          <span className="tag" key={singletag}>
          {blogTagsHelper.getTitleFromSlug(singletag)}
          </span>
        ))}
      </div>

      <ListTLink
        to={postUrl}
        exit={{
          length: 1,
          zIndex: 2, // exit default is 0
          trigger: ({ exit, e, node }) => animateObjects(exit, node),
        }}
        entry={{
          delay: 0.5,
          length: 0.6,
          trigger: ({ entry, node }) => newContent(node),
        }}
      >
        <div className="card-meta">
          <div className="heading5">{post.frontmatter.date}</div>
          <h3 dangerouslySetInnerHTML={{__html: useTitleForView}} />
        </div>
        <div className="cta-wrapper">
          <button className="cta magnetic-button">Loe edasi</button>
        </div>

        <GatsbyImage
          image={image}
          alt={post.frontmatter.thumbnailalt}
          className="post-thumbnail"
        />
      </ListTLink>
    </div>
  );
}
